import { createStore } from 'vuex';
import i18n from '../i18n';

export default new createStore({
    state: {
        layout: 'app',
        is_show_sidebar: true,
        is_show_search: false,
        is_dark_mode: false,
        dark_mode: 'light',
        locale: null,
        menu_style: 'vertical',
        layout_style: 'full',
        countryList: [
            { code: 'zh', name: 'Chinese' },
            { code: 'da', name: 'Danish' },
            { code: 'en', name: 'English' },
            { code: 'fr', name: 'French' },
            { code: 'de', name: 'German' },
            { code: 'el', name: 'Greek' },
            { code: 'hu', name: 'Hungarian' },
            { code: 'it', name: 'Italian' },
            { code: 'ja', name: 'Japanese' },
            { code: 'pl', name: 'Polish' },
            { code: 'pt', name: 'Portuguese' },
            { code: 'ru', name: 'Russian' },
            { code: 'es', name: 'Spanish' },
            { code: 'sv', name: 'Swedish' },
            { code: 'tr', name: 'Turkish' },
        ],
        userLogado: false,
        telaTrancada: false,
        buscaFavorito: false,
        userDados: {
            id: 0,
            nome: "John Doe",
            email: "email@email.com",
            razaosocial:"",
            cnpj: "",
            token: "",
            senha: "",
        },
        buscaDados: {
            dataspub: '',
            mpls: {
                pls: '',
                id: 0,
                nome: '',
              },
            tpdata: 'd',
            tpfiltro: '',
            opcObj: "1",
            portais: [],
        },
        pregaoDados: {
            pregao: {},
            itens: [],
        },
    },
    mutations: {
        setLayout(state, payload) {
            state.layout = payload;
        },
        toggleSideBar(state, value) {
            state.is_show_sidebar = value;
        },
        toggleSearch(state, value) {
            state.is_show_search = value;
        },
        toggleLocale(state, value) {
            value = value || 'en';
            i18n.global.locale = value;
            localStorage.setItem('i18n_locale', value);
            state.locale = value;
        },
        toggleDarkMode(state, value) {
            //light|dark|system
            value = value || 'light';
            localStorage.setItem('dark_mode', value);
            state.dark_mode = value;
            if (value == 'light') {
                state.is_dark_mode = false;
            } else if (value == 'dark') {
                state.is_dark_mode = true;
            } else if (value == 'system') {
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    state.is_dark_mode = true;
                } else {
                    state.is_dark_mode = false;
                }
            }

            if (state.is_dark_mode) {
                document.querySelector('body').classList.add('dark');
            } else {
                document.querySelector('body').classList.remove('dark');
            }
        },
        toggleMenuStyle(state, value) {
            //horizontal|vertical|collapsible-vertical
            value = value || '';
            localStorage.setItem('menu_style', value);
            state.menu_style = value;
            if (!value || value === 'vertical') {
                state.is_show_sidebar = true;
            } else if (value === 'collapsible-vertical') {
                state.is_show_sidebar = false;
            }
        },
        toggleLayoutStyle(state, value) {
            //boxed-layout|large-boxed-layout|full
            value = value || '';
            localStorage.setItem('layout_style', value);
            state.layout_style = value;
        },

        //######## MEUS ##########################################################################################################################################
        changeUserDados(state, newUserDados) {
            state.userDados = newUserDados;
            localStorage.setItem('userDados', JSON.stringify(newUserDados));
        },
        changeUserLogado(state, bVal) {
            state.userLogado = bVal;
            localStorage.setItem('userLogado', bVal);
        },
        changeTelaTrancada(state, bVal) {
            state.telaTrancada = bVal;
            localStorage.setItem('telaTrancada', bVal);
        },
        changeBuscaDados(state, value) {
            state.buscaDados = value;
            localStorage.setItem('buscaDados', JSON.stringify(value));
        },
        changeBuscaFavorito(state, value) {
            state.buscaFavorito = value;
        },
        changePregaoRegDados(state, value) {
            state.pregaoDados.pregao = value;
        },
        changePregaoItensDados(state, value) {
            state.pregaoDados.itens = value;
        },

    },
    getters: {
        layout(state) {
            return state.layout;
        },

        //######################################### MEUS #############################################
        userDados(state) {
            return state.userDados;
        },
        userLogado(state) {
            return state.userLogado;
        },
        telaTrancada(state) {
            return state.telaTrancada;
        },
        buscaDados(state) {
            return state.buscaDados;
        },
        pregaoRegDados(state) {
            return state.pregaoDados.pregao;
        },
        pregaoItensDados(state) {
            return state.pregaoDados.itens;
        },
        userLics(state) {
            return state.userDados.licencas;
        },
        buscaFavorito(state) {
            return state.buscaFavorito;
        },

    },
    actions: {},
    modules: {},
});
