import { createRouter, createWebHistory } from 'vue-router';

import Home from '../views/index.vue';
import store from '../store';

const routes = [
    //dashboard
    { path: '/', name: 'Home', component: Home },

    {
        path: '/index2',
        name: 'index2',
        component: () => import(/* webpackChunkName: "index2" */ '../views/index2.vue'),
    },

    //configs
    {
        path: '/configs/loginsportal',
        name: 'loginsportal',
        component: () => import(/* webpackChunkName: "loginsportal" */ '../views/configs/loginsportal.vue'),
    },
    {
        path: '/configs/alertasmobile',
        name: 'alertasmobile',
        component: () => import(/* webpackChunkName: "alertasmobile" */ '../views/configs/alertasmobile.vue'),
    },
    {
        path: '/configs/representados',
        name: 'representados',
        component: () => import(/* webpackChunkName: "alertasmobile" */ '../views/configs/representados.vue'),
    },

    //pages
    {
        path: '/pages/maintenence',
        name: 'maintenence',
        component: () => import(/* webpackChunkName: "pages-maintenence" */ '../views/pages/maintenence.vue'),
        meta: { layout: 'auth' },
    },

    //auth
    {
        path: '/auth/login-boxed',
        name: 'login-boxed',
        component: () => import(/* webpackChunkName: "auth-login-boxed" */ '../views/auth/login_boxed.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/auth/logout',
        name: 'logout',
        component: () => import(/* webpackChunkName: "auth-login-boxed" */ '../views/auth/logout.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/auth/lockscreen-boxed',
        name: 'lockscreen-boxed',
        component: () => import(/* webpackChunkName: "auth-lockscreen-boxed" */ '../views/auth/lockscreen_boxed.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/auth/pass-recovery-boxed',
        name: 'pass-recovery-boxed',
        component: () => import(/* webpackChunkName: "auth-pass-recovery-boxed" */ '../views/auth/pass_recovery_boxed.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/auth/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "auth-login" */ '../views/auth/login.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/auth/lockscreen',
        name: 'lockscreen',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/auth/lockscreen.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/auth/troca-senha-boxed',
        name: 'troca-senha-boxed',
        component: () => import(/* webpackChunkName: "auth-troca-senha-boxed" */ '../views/auth/troca-senha-boxed.vue'),
        meta: { layout: 'auth' },
    },

    //users
    {
        path: '/users/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "users-profile" */ '../views/users/profile.vue'),
    },
    {
        path: '/users/troca-senha',
        name: 'troca-senha',
        component: () => import(/* webpackChunkName: "users-troca-senha" */ '../views/users/troca_senha.vue'),
    },

    //apps
    {
        path: '/apps/pregoes',
        name: 'pregoes',
        component: () => import(/* webpackChunkName: "apps-pregoes" */ '../views/apps/pregoes/pregoes.vue'),
    },
    {
        path: '/apps/busca',
        name: 'busca',
        component: () => import(/* webpackChunkName: "apps-busca" */ '../views/apps/busca/busca.vue'),
    },
    {
        path: '/apps/calendar',
        name: 'calendar',
        component: () => import(/* webpackChunkName: "apps-calendar" */ '../views/apps/pregoes/calendar.vue'),
    },
];

const router = new createRouter({
    // mode: 'history',
    history: createWebHistory(),
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {

    let bUserLogado = store.getters.userLogado;
    let bTelaTrancada = store.getters.telaTrancada;

    if (!bUserLogado) { //verificando em localstorage
        let _userDados = JSON.parse(localStorage.getItem('userDados'));
        if (_userDados) {
            let _dadosBusca = localStorage.getItem('buscaDados')
                ? JSON.parse(localStorage.getItem('buscaDados'))
                : null;

            if (_dadosBusca)
                store.commit('changeBuscaDados', _dadosBusca);

            bTelaTrancada = JSON.parse(localStorage.getItem('telaTrancada'));
            if (_userDados && _userDados.token) {
                store.commit('changeUserLogado', true);
                store.commit('changeUserDados', _userDados);
                bUserLogado = true;
            }
        } else {
            console.log("Logina primeira vez...");
        }
    }


    if (to.meta && to.meta.layout && to.meta.layout == 'auth') {
        store.commit('setLayout', 'auth');
    } else {

        if (!bUserLogado) {
            return next("/auth/login-boxed");
        }

        if (bTelaTrancada) {
            return next("/auth/lockscreen-boxed");
        }

        store.commit('setLayout', 'app');
    }
    next(true);
});

export default router;
