import moment from "moment";

export function removeRegArray(coddel, regs) {
  var objDel = regs.find(i => i.id == coddel);
  var index = regs.indexOf(objDel);
  if (index > -1) {
    regs.splice(index, 1);
  }
  return regs;
}
export function validarEmail(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export function formatReal(val) {
  var formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return formatter.format(val);
}

export function formataData(data) {
  if (!data) return '';
  return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY");
}

export function dataHoraAgora(hora = null) {
  const dataAtual = moment(new Date(), "DD/MM/YYYY");
  if (hora) {
    const [horas, minutos, segundos] = hora.split(':');
    dataAtual.set({
      hour: parseInt(horas, 10),
      minute: parseInt(minutos, 10),
      second: parseInt(segundos, 10),
    });
  }
  return dataAtual.format("YYYY-MM-DDTHH:mm:ss");
}

export function alteraHoraData(data, hora) {
  data = moment(data)
  .set({
      hour: parseInt(hora.split(":")[0], 10),
      minute: parseInt(hora.split(":")[1], 10),
      second: parseInt(hora.split(":")[2], 10),
  })
  .toISOString();

  return data;
}

export function formataDataJson(data) {
  if (!data) return '';
  return moment(data, "DD/MM/YYYY hh:mm:ss").format("YYYY-MM-DDThh:mm:ss");
}

export function formataDataHora(data) {
  if (!data) return '';
  return moment(data, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY hh:mm");
}

export function verificaDataVal(dataval) {
  let dtReal = moment(dataval);
  let dataAgora = moment();
  if (dtReal < dataAgora) {
  } else {
  }
  return dtReal > dataAgora;
}

export async function showAlert(icon, msg) {
  window.Swal.fire({
    icon: icon,
    title: msg,
    padding: '2em',
    allowOutsideClick: false,
  });
}

export async function showToast(icon, msg, xpos = 'top-end') {
  const toast = window.Swal.mixin({
    toast: true,
    position: xpos,
    showConfirmButton: false,
    timer: 3000,
    padding: '2em'
  });
  toast.fire({
    icon: icon,
    title: msg,
    padding: '2em'
  });
}

export function showLoading(msg) {
  window.Swal.fire({
    title: 'AGUARDE',
    html: msg,
    allowOutsideClick: false,
    didOpen: () => {
      window.Swal.showLoading();
    },
    willClose: () => {}
  }).then(function (result) {
    if (result.dismiss === window.Swal.DismissReason.timer) {
      console.log('I was closed by the timer');
    }
  });
}

export function closeLoading() {
  window.Swal.close();
}

export function calcularPorcentagem(total, valor) {
  if (total === 0) {
    throw new Error("O total não pode ser zero.");
  }
  return ((valor / total) * 100).toFixed(2);
}


export async function showConfirm(icon, title, msg) {
  try {
      const result = await window.Swal.fire({
          icon: icon,
          title: title,
          html: msg,
          showCancelButton: true,
          confirmButtonText: 'SIM',
          cancelButtonText: 'CANCELAR',
          padding: '2em',
      });

      return result.isConfirmed;
  } catch (error) {
      console.error('Erro ao exibir confirmação:', error);
      return false; // Retorna false em caso de erro
  }
}
