<template>
    <div class="layout-px-spacing dash_1">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:;">Dashboard</a></li>
                                <li class="breadcrumb-item active" aria-current="page"><span>Resumo</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>

        <div class="row layout-top-spacing">

            <div class="alert alert-icon-left alert-dismissible alert-light-success mb-4" role="alert"
                v-if="msgLogin != '' && !msgLogin.includes('Erro')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-alert-triangle">
                    <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z">
                    </path>
                    <line x1="12" y1="9" x2="12" y2="13"></line>
                    <line x1="12" y1="17" x2="12" y2="17"></line>
                </svg>
                <strong>ATENÇÃO!</strong> {{ msgLogin }}
                <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">×</button>
            </div>
            <div class="alert alert-icon-left alert-dismissible alert-light-danger mb-4" role="alert"
                v-if="msgLogin != '' && msgLogin.includes('Erro')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-alert-circle">
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="12" y1="8" x2="12" y2="12"></line>
                    <line x1="12" y1="16" x2="12" y2="16"></line>
                </svg>
                <strong>ATENÇÃO!</strong> {{ msgLogin }}
                <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">×</button>
            </div>

            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
                <div class="widget widget-summary-1">
                    <div class="widget-heading">
                        <h5>Histórico geral</h5>
                    </div>
                    <vue-element-loading :active="isLoadingRes" spinner="bar-fade-scale" />
                    <div class="widget-content" v-if="dataTots">
                        <div class="order-summary">
                            <div class="summary-list summary-income">
                                <div class="summery-info">
                                    <div class="w-icon">
                                        <div class="w-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round">
                                                <path d="M9 4H4v4c0 3 2 5 5 5V4z"></path>
                                                <path d="M15 4h5v4c0 3-2 5-5 5V4z"></path>
                                                <path d="M8 12h8"></path>
                                                <path d="M5 20h14"></path>
                                                <path d="M10 20v-3h4v3"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="w-summary-details">
                                        <div class="w-summary-info">
                                            <h6>Disputou <span class="summary-count">{{
                                                formatReal(dataTots.TotalValorParticipou) }} </span></h6>
                                            <p class="summary-average">100%</p>
                                        </div>
                                        <div class="w-summary-info">
                                            {{ dataTots.QntItensParticipou }} Itens
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="summary-list summary-profit">
                                <div class="summery-info">
                                    <div class="w-icon">
                                        <div class="w-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round">
                                                <path d="M8 4h8v5a4 4 0 0 1-8 0V4z"></path>
                                                <path d="M4 6v3c0 4 3 7 8 7s8-3 8-7V6"></path>
                                                <path d="M10 20h4"></path>
                                                <path d="M8 16h8"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="w-summary-details">
                                        <div class="w-summary-info">
                                            <h6>Venceu <span class="summary-count">{{
                                                formatReal(dataTots.TotalValorGanhou) }}</span></h6>
                                            <p class="summary-average">{{
                                                calcularPorcentagem(dataTots.QntItensParticipou,
                                                    dataTots.QntItensGanhou) }}%</p>
                                        </div>
                                        <div class="w-summary-info">
                                            {{ dataTots.QntItensGanhou }} Itens
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="summary-list summary-expenses">
                                <div class="summery-info">
                                    <div class="w-icon">
                                        <div class="w-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round">
                                                <circle cx="12" cy="12" r="7"></circle>
                                                <path d="M9 9l3 3 3-3"></path>
                                                <path d="M10 15h4"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="w-summary-details">
                                        <div class="w-summary-info">
                                            <h6>Perdeu <span class="summary-count">{{
                                                formatReal(dataTots.TotalValorParticipou -
                                                    dataTots.TotalValorGanhou) }}</span></h6>
                                            <p class="summary-average">{{
                                                calcularPorcentagem(dataTots.QntItensParticipou,
                                                    dataTots.QntItensParticipou - dataTots.QntItensGanhou) }}%</p>
                                        </div>
                                        <div class="w-summary-info">
                                            {{ dataTots.QntItensParticipou - dataTots.QntItensGanhou }} Itens
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="summary-list minhacor">
                                <div class="summery-info">
                                    <div class="w-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-tag">
                                            <path
                                                d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z">
                                            </path>
                                            <line x1="7" y1="7" x2="7" y2="7"></line>
                                        </svg>
                                    </div>
                                    <div class="w-summary-details" v-if="dataProdMaisVend">
                                        <div class="w-summary-info">
                                            <h6>Produto mais vendido <span class="summary-count">{{
                                                dataProdMaisVend.NomeProduto }}</span></h6>
                                            <p class="summary-average">{{ formatReal(dataProdMaisVend.TotalValor) }}</p>
                                        </div>
                                        <div class="w-summary-info">
                                            {{ dataProdMaisVend.TotalQuantidade }} Itens em {{
                                                dataProdMaisVend.Ocorrencias }} ocorrências
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
                <div class="widget widget-summary-1">
                    <div class="widget-heading">
                        <h5>Vendas nos últimos 7 dias</h5>
                    </div>
                    <vue-element-loading :active="isLoading7Dias" spinner="bar-fade-scale" />
                    <div class="widget-content" v-if="lucro7Dias">
                        <apex-chart v-if="!isLoading7Dias" height="400" type="bar" :options="vendas7_options"
                            :series="vendas7_series"></apex-chart>
                    </div>
                </div>
            </div>

            <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-visitor-by-browser">
                    <div class="widget-heading">
                        <h5>Principais concorrentes</h5>
                    </div>
                    <vue-element-loading :active="isLoading5ConcsWn" spinner="bar-fade-scale" />
                    <div class="widget-content" v-if="data5ConcWin">
                        <div class="browser-list" v-for="(item, index) in data5ConcWin" :key="index">
                            <div class="w-icon icon-fill-primary">
                                <!-- Classe para exibir a numeração -->
                                <div class="number-icon">{{ index + 1 }}º</div>
                            </div>
                            <div class="w-browser-details">
                                <div class="w-browser-info">
                                    <h6>{{ item.razaosocial }}</h6>
                                    <p class="browser-count">{{ formatReal(item.valor) }}</p>
                                </div>
                                <div class="w-browser-stats">
                                    <div class="progress">
                                        <div role="progressbar" aria-valuemin="0" aria-valuemax="100"
                                            :aria-valuenow="calcularPorcentagem(totalConcs5Win, item.valor)"
                                            class="progress-bar bg-gradient-primary"
                                            :style="{ width: calcularPorcentagem(totalConcs5Win, item.valor) + '%' }">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row layout-top-spacing">
            <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-sales-category">
                    <div class="widget-heading">
                        <h5>Seus pregões</h5>
                    </div>
                    <vue-element-loading :active="isLoadingBars" spinner="bar-fade-scale" />
                    <div class="widget-content">
                        <apex-chart v-if="!isLoadingBars" height="460" type="donut" :options="sales_donut_options"
                            :series="sales_donut_series"></apex-chart>
                    </div>
                </div>
            </div>

            <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-top-selling-products">
                    <div class="widget-heading">
                        <h5>Últimos pregões adicionados</h5>
                    </div>
                    <vue-element-loading :active="isLoadingLista" spinner="bar-fade-scale" />

                    <div class="widget-content table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>
                                        <div class="th-content">Portal</div>
                                    </th>
                                    <th>
                                        <div class="th-content th-heading">Objeto</div>
                                    </th>
                                    <th>
                                        <div class="th-content th-heading">Publicação</div>
                                    </th>
                                    <th>
                                        <div class="th-content">Disputa</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="datalics" v-for="reg in datalics" :key="reg.id">
                                    <td>
                                        <div class="td-content"><span class="pricing">{{ reg.nomeportal }}</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="pricing">{{ reg.objeto }}</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="discount-pricing">{{
                                            formataData(reg.datapublicacao) }}</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content">{{ formataDataHora(reg.datadisputa) }}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import '@/assets/sass/widgets/widgets.scss';
import { computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import ApexChart from 'vue3-apexcharts';
import connServ from '@/api/conserv';
import { showAlert, formataData, formataDataHora, formatReal, calcularPorcentagem } from '@/mixins/fcgeral';
import VueElementLoading from 'vue-element-loading';
import { useMeta } from '@/composables/use-meta';

useMeta({ title: 'Minhas licitações' });

const router = useRouter();
const store = useStore();

const userDados = ref(store.getters.userDados);
const show_wait = ref(false);
const datalics = ref(null);
const values_lics = ref([]);
const labs_lics = ref([]);
const colors_lics = ref([]);
const isLoadingBars = ref(true);
const isLoading7Dias = ref(true);
const isLoadingRes = ref(false)
const isLoadingLista = ref(true);
const isLoading5ConcsWn = ref(true);
const dataTots = ref(null);
const data5Conc = ref(null);
const data5ConcWin = ref(null);
const dataProdMaisVend = ref(null);
const lucro7Dias = ref(null);
const is_dark = computed(() => store.state.is_dark_mode);
const totalConcs5Win = ref(0);
const msgLogin = ref('');


// Sales by Category
const sales_donut_series = ref([]);
const sales_donut_options = ref({});

// vendas 7 dias
const vendas7_series = ref([]);
const vendas7_options = ref({});

const updateDonutChartOptions = () => {
    sales_donut_options.value = {
        chart: {
            type: 'donut',
        },
        dataLabels: { enabled: true },
        expandOnClick: !is_dark.value,
        colors: is_dark.value ? ['#5c1ac3', '#e2a03f', '#e7515a', '#e2a03f'] : colors_lics.value,
        legend: {
            position: 'right',
            horizontalAlign: 'center',
            fontSize: '12px',
            markers: { width: 10, height: 10 },
            height: 150,
            offsetY: 20,
            itemMargin: { horizontal: 8, vertical: 0 },
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '65%',
                    background: 'transparent',
                    labels: {
                        show: true,
                        name: { show: true, fontSize: '29px', fontFamily: 'Nunito, sans-serif', offsetY: -5 },
                        value: {
                            show: true,
                            fontSize: '26px',
                            fontFamily: 'Nunito, sans-serif',
                            color: is_dark.value ? '#bfc9d4' : undefined,
                            offsetY: 16,
                            formatter: function (val) {
                                return val;
                            },
                        },
                        total: {
                            show: true,
                            label: 'Total',
                            color: '#888ea8',
                            fontSize: '29px',
                            formatter: function (w) {
                                return w.globals.seriesTotals.reduce(function (a, b) {
                                    return a + b;
                                }, 0);
                            },
                        },
                    },
                },
            },
        },
        labels: labs_lics.value,
    };

    if (is_dark.value) {
        sales_donut_options.value.states = {
            hover: { filter: { type: 'none' } },
            active: { filter: { type: 'none' } },
        };
    }
};

const ListaLicitacoes24H = async () => {
    let json = await connServ('api/DashPregoes/ListaPregoesDash', 'get', '', router);
    if (json.bstatus && json.dados.status) {
        let vals = json.dados.registro.map((a) => a.total);
        let labs = json.dados.registro.map((a) => a.nomeportal);
        let cores = json.dados.registro.map((a) => a.cor);

        // Certifique-se de que são arrays
        colors_lics.value = Array.isArray(cores) ? cores : [];
        values_lics.value = Array.isArray(vals) ? vals : [];
        labs_lics.value = Array.isArray(labs) ? labs : [];

        // Atualiza as séries e as opções do gráfico
        sales_donut_series.value = values_lics.value;
        updateDonutChartOptions();
        isLoadingBars.value = false;

    } else {
        showAlert('error', json.msg);
    }
};

const ListaUltimasPortais = async () => {
    show_wait.value = true;
    isLoadingLista.value = true;
    let json = await connServ('api/DashPregoes/ListaUltimosCad', 'get', '', router);
    if (json.bstatus && json.dados.status) {
        datalics.value = [...json.dados.registro];
        isLoadingLista.value = false;
    } else {
        showAlert('error', json.msg);
    }
    show_wait.value = false;
};

const PegaTotaisItens = async () => {
    isLoadingRes.value = true;
    let json = await connServ('api/DashPregoes/PegaTotaisItens', 'get', '', router);
    if (json.bstatus && json.dados.status) {
        dataTots.value = json.dados.registro;
    } else {
        showAlert('error', json.msg);
    }
    isLoadingRes.value = false;
};

const ProdutoMaisVendido = async () => {
    //isLoadingRes.value = true;
    let json = await connServ('api/DashPregoes/ProdutoMaisVendido', 'get', '', router);
    if (json.bstatus && json.dados.status) {
        dataProdMaisVend.value = json.dados.registro;
    } else {
        showAlert('error', json.msg);
    }
    //isLoadingRes.value = false;
};


const Pega5ConcsWinsFatu = async () => {
    let json = await connServ('api/DashPregoes/Pega5ConcsWinFatu', 'get', '', router);
    if (json.bstatus && json.dados.status) {
        data5ConcWin.value = json.dados.registro;
    } else {
        showAlert('error', json.msg);
    }

    totalConcs5Win.value = data5ConcWin.value.reduce((acc, obj) => acc + obj.valor, 0).toFixed(2);
    console.log('totalConcs5Win.value = ', totalConcs5Win.value);
    isLoading5ConcsWn.value = false;
};

const PegaLucro7Dias = async () => {
    let json = await connServ('api/DashPregoes/PegaLucro7Dias', 'get', '', router);
    if (json.bstatus && json.dados.status) {
        lucro7Dias.value = json.dados.registro;

        // Criar série para o gráfico
        vendas7_series.value = [{
            name: "Total",
            data: lucro7Dias.value.map(item => parseFloat(item.Total.toFixed(2)))
        }];

        // Atualizar opções do gráfico com categorias no eixo X
        vendas7_options.value = {
            chart: {
                type: 'bar',
                height: 400,
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 5,
                    horizontal: false,
                    columnWidth: '50%',
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: lucro7Dias.value.map(item => {
                    const date = new Date(item.Data);
                    return date.toLocaleDateString('pt-BR'); // Formato dd/MM/yyyy
                }),
                labels: {
                    rotate: -45, // Rotaciona as labels
                    style: {
                        fontSize: '10px',
                        fontFamily: 'Nunito, sans-serif',
                    },
                },
                title: {
                    text: 'Datas'
                }
            },
            yaxis: {
                title: {
                    text: 'Total (R$)'
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: (val) => `R$ ${val.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`
                }
            }
        };

        isLoading7Dias.value = false; // Finaliza carregamento
    } else {
        showAlert('error', json.msg);
        isLoading7Dias.value = false;
    }
};

onMounted(async () => {
    if (userDados.value.msgAddLogin != "") {
        msgLogin.value = userDados.value.msgAddLogin;
        userDados.value.msgAddLogin = "";
        store.commit('changeUserDados', userDados.value);
    }
    ListaLicitacoes24H();
    ListaUltimasPortais();
    PegaTotaisItens();
    PegaLucro7Dias();
    Pega5ConcsWinsFatu();
    ProdutoMaisVendido();
});

</script>

<style scoped>
.number-icon {
    font-size: 1.5rem;
    font-weight: bold;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #f0f0f0;
}

.summary-list.minhacor {
    background-color: #a72892;
    /* Fundo verde */
    border-color: #9228a7;
    /* Borda */
    color: #456488;
    /* Texto branco */
}

.summary-list.minhacor .w-icon svg {
    stroke: #456488;
    /* Cor branca para o ícone */
    fill: none;
    /* Sem preenchimento */
    width: 24px;
    height: 24px;
}

.summary-list.minhacor h6,
.summary-list.minhacor .summary-count,
.summary-list.minhacor .summary-average {
    color: #456488;
    /* Texto branco */
}
</style>
