<template>
    <button
      v-if="isVisible"
      @click="scrollToTop"
      class="back-to-top-button"
    >
      ▲
    </button>
  </template>
  
  <script setup>
  import { ref, onMounted, onUnmounted } from 'vue';
  
  const isVisible = ref(false);
  
  function toggleVisibility() {
    isVisible.value = window.scrollY > 600;
  }
  
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  
  onMounted(() => {
    window.addEventListener('scroll', toggleVisibility);
  });
  
  onUnmounted(() => {
    window.removeEventListener('scroll', toggleVisibility);
  });
  </script>
  
  <style scoped>
  .back-to-top-button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    z-index: 9999;
  }
  
  .back-to-top-button:hover {
    background-color: #0056b3;
  }
  </style>
  